


















































import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';

const userService = new UserService();

@Component
export default class EditUser extends Vue {
  public name: string = '';

  public password: string = '';

  public username: string = '';

  public loading: boolean = false;

  async created() {
    const { data } = await userService.getAdminById(this.$route.params.id);
    this.name = data.name;
    this.username = data.username;
  }

  async updateAdmin() {
    try {
      this.loading = true;
      await userService.updateAdmin(this.$route.params.id, {
        password: this.password ? this.password : undefined,
        name: this.name ? this.name : undefined,
      });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
      this.$router.push('/admins');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}

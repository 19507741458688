/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class AdminService extends AppService {
  public getAdminById(id: string) {
    return this.makeRequest('GET', `/admins/${id}`);
  }

  public updateAdmin(
    id: string,
    updateData: { password?: string; name?: string; status?: string },
  ) {
    return this.makeRequest('PUT', `/admins/${id}`, updateData);
  }

  public createAdmin(data: { password: string; name: string; username: string }) {
    return this.makeRequest('POST', '/admins', data);
  }

  public userRegisterSummary() {
    return this.makeRequest('GET', '/users/summary');
  }
}
























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import TransactionService from '@/services/TransactionService';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';

const transactionService = new TransactionService();

@Component({
  components: {
    BaseDataTable,
    BaseDateRangeSearch,
    Auth,
  },
})
export default class Transaction extends Vue {
  public loading: boolean = false;

  public depositSummary: number = 0;

  public withdrawSummary: number = 0;

  public transactions: number = 0;

  // eslint-disable-next-line max-len
  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  async search(data: any) {
    this.range = data;
  }

  @Watch('range')
  async onRangeChange() {
    await this.getTransactionSummary();
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  // eslint-disable-next-line class-methods-use-this
  get transactionUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions`;
  }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  // eslint-disable-next-line @typescript-eslint/camelcase
  async getTransactionSummary() {
    try {
      const { data } = await transactionService.getTransactionSummary(this.range);
      this.depositSummary = data.depositSummary.toFixed(2);
      this.withdrawSummary = data.withdrawSummary.toFixed(2);
      this.transactions = data.length;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async created() {
    await this.getTransactionSummary();
  }
}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/deposit',
    name: 'DepositRequest',
    component: () => import('@/pages/DepositRequest.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/withdraw',
    name: 'WithdrawRequest',
    component: () => import('@/pages/WithdrawRequest.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('@/pages/Transaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/admins',
    name: 'AdminList',
    component: () => import('@/pages/UserList.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/admins/create',
    name: 'CreateAdmin',
    component: () => import('@/pages/CreateUser.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/admins/:id',
    name: 'EditAdmin',
    component: () => import('@/pages/EditUser.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/pages/ChangePassword.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/banks',
    name: 'BankList',
    component: () => import('@/pages/BankList.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/banks/create',
    name: 'CreateBank',
    component: () => import('@/pages/CreateBank.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/banks/:id',
    name: 'EditBank',
    component: () => import('@/pages/EditBank.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/promotions',
    name: 'PromotionList',
    component: () => import('@/pages/PromotionList.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/promotions/create',
    name: 'CreatePromotion',
    component: () => import('@/pages/CreatePromotion.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/promotions/:id',
    name: 'EditPromotion',
    component: () => import('@/pages/EditPromotion.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/affiliate-setting',
    name: 'AffiliateSetting',
    component: () => import('@/pages/AffiliateSetting.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/affiliate-userlist',
    name: 'AffiliateUserList',
    component: () => import('@/pages/AffiliateUserList.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/affiliate-userlist/:id',
    name: 'AffiliateUserListDetail',
    component: () => import('@/pages/AffiliateUserListDetail.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/player',
    name: 'PlayerList',
    component: () => import('@/pages/PlayerList.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/player/:id',
    name: 'EditPlayer',
    component: () => import('@/pages/EditPlayer.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/token-manage',
    name: 'TokenManage',
    component: () => import('@/pages/TokenManage.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiredLogin) {
    if (store.state.Auth.isLoggedIn && !to.meta.super) {
      next();
      return;
    }
    if (store.state.Auth.isLoggedIn && to.meta.super) {
      if (store.state.Auth.username === 'superadmin') {
        next();
        return;
      }
      return;
    }
    next('/');
  } else {
    next();
  }
});

export default router;

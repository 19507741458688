




















































import { Component, Vue } from 'vue-property-decorator';
import BankService from '@/services/BankService';

const bankService = new BankService();

@Component
export default class EditBank extends Vue {
  public bank: string = '';

  public accountNumber: string = '';

  public name: string = '';

  public index: string|number = '';

  public loading: boolean = false;

  async created() {
    const { data } = await bankService.getBankById(this.$route.params.id);
    console.log(data);
    this.bank = data.bank;
    this.accountNumber = data.account_number;
    this.bank = data.bank;
    this.index = data.index;
    this.name = data.name;
    console.log(data);
  }

  async editBank() {
    try {
      this.loading = true;
      await bankService.editBank({
        // eslint-disable-next-line
        bank_id: this.$route.params.id,
        bank: this.bank,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_number: this.accountNumber,
        name: this.name,
      });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
      this.$router.push('/banks');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}





























































































































































import { Component, Vue } from 'vue-property-decorator';
import BankService from '@/services/BankService';

const bankService = new BankService();

@Component
export default class BankList extends Vue {
  public loading: boolean = false;

  public configLoading: boolean = false;

  public data: Array<any> = [];

  public bankConfig: { auto_create_request: boolean } = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    auto_create_request: false,
  };

  async created() {
    await this.fetchBank();
    await this.fetchBankConfig();
  }

  public async fetchBank() {
    const { data } = await bankService.getAllBank();
    this.data = [...data];
  }

  public async fetchBankConfig() {
    const { data } = await bankService.getBankConfig();
    this.bankConfig = { ...data };
  }

  public async setBankConfig() {
    try {
      this.configLoading = true;
      await bankService.setBankConfig(this.bankConfig);
      this.fetchBankConfig();
      this.configLoading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'บันทึกการตั้งค่าแล้ว', error: false });
    } catch (e) {
      this.configLoading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  public async deleteBank(id: string) {
    try {
      this.loading = true;
      await bankService.deleteBank(id);
      this.fetchBank();
      this.$store.commit('Snackbar/showNotification', { message: 'ลบบัญชีแล้ว', error: false });
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  public async healthCheck(bank: string, accountNumber: string) {
    try {
      this.loading = true;
      this.$store.commit('Snackbar/showNotification', { message: `กำลังตรวจสอบบัญชี ${bank} ${accountNumber} [ การตวจสอบบัญชีอาจใช้เวลาประมาณ 3-5 นาที ]`, error: false });
      const { data } = await bankService.healthCheck(bank, accountNumber);
      if (!data) {
        this.$store.commit('Snackbar/showNotification', { message: 'อาจเกิดจากการโดนธนาคารระงับการใช้งานหรือข้อมูลบัญชีไม่ถูกต้อง,ท่านสามารถแก้ไขข้อมูลบัญชีเพื่อลองใหม่อีกครั้ง', error: true });
      } else {
        this.$store.commit('Snackbar/showNotification', { message: 'ขุ้อมูลถูกต้อง', error: false });
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" รายการธุรกรรมประจำวันที่ "+_vm._s(_vm.dateText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"dark":"","color":"green","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดฝากทั้งหมด")]),_c('h1',[_vm._v(" "+_vm._s(_vm.depositSummary)+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"dark":"","color":"primary","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดถอนทั้งหมด")]),_c('h1',[_vm._v(" "+_vm._s(_vm.withdrawSummary)+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"dark":"","color":"purple","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("จำนวนรายการ")]),_c('h1',[_vm._v(" "+_vm._s(_vm.transactions)+" ")]),_c('span',[_vm._v("รายการ")])])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"dark":"","color":"pink","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดฝาก - ยอดถอน")]),_c('h1',[_vm._v(" "+_vm._s((_vm.depositSummary - _vm.withdrawSummary).toFixed(2))+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('base-date-range-search',{ref:"SEARCH",attrs:{"default-date":_vm.range.split(',')},on:{"search":_vm.search}})],1),_c('v-card-text',[_c('base-data-table',{ref:"TRANSACTION_TABLE",attrs:{"data-url":_vm.transactionUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"headers":[
                  { text: 'ผู้ร้องขอ', value: 'user_id' },
                  { text: 'รับคำขอโดย', value: 'assisstant_id' },
                  { text: 'รหัสอ้างอิงคำขอ', value: 'request_id' },
                  { text: 'จำนวนเงิน', value: 'amount' },
                  { text: 'ประเภทธุรกรรม', value: 'type' },
                  { text: 'จำนวนเงินก่อนทำธุรกรรม', value: 'before' },
                  { text: 'จำนวนเงินหลังทำธุรกรรม', value: 'after' },
                  { text: 'สร้างเมื่อ', value: 'created_at' },
                  { text: 'แก้ไขเมื่อ', value: 'updated_at' } ],"filters":{
                  range: _vm.range
                }},scopedSlots:_vm._u([{key:"user_id",fn:function(ref){
                var user_id = ref.props.user_id;
return [(user_id)?_c('span',[_vm._v(_vm._s(user_id.username))]):_vm._e(),_c('br'),(user_id)?_c('span',[_vm._v(" "+_vm._s(user_id.bank)+" "+_vm._s(user_id.account_number)+" ")]):_vm._e()]}},{key:"assisstant_id",fn:function(ref){
                var assisstant_id = ref.props.assisstant_id;
return [(!assisstant_id)?_c('span',[_vm._v(" ระบบอัติโนมัติ ")]):_c('span',[_vm._v(" "+_vm._s(assisstant_id.name)+" ")])]}},{key:"request_id",fn:function(ref){
                var request_id = ref.props.request_id;
return [(request_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(request_id._id)+" ")])]}}],null,true)},[_c('h3',[_vm._v("รายละเอียดคำขอ")]),_c('span',[_vm._v("ธนาคาร: "+_vm._s(request_id.to_bank)+" "+_vm._s(request_id.to_account_number))]),_c('br'),_c('span',[_vm._v("จำนวนเงิน: "+_vm._s(request_id.amount))]),_c('br'),(request_id.request_type === 'DEPOSIT' && request_id.slip)?_c('img',{attrs:{"src":request_id.slip,"height":"540 * 1.6 px","width":"auto"}}):_vm._e()]):_c('span',[_vm._v(" ดึงเครดิตกลับ ")])]}},{key:"created_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.updated_at))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }

















































































































































































































































































































/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import PlayerService from '@/services/PlayerService';
import BankService from '@/services/BankService';
import TransactionService from '@/services/TransactionService';

const playerService = new PlayerService();
const bankService = new BankService();
const transactionService = new TransactionService();

@Component({
  components: {
    BaseDataTable,
    Auth,
  },
})
export default class PlayerList extends Vue {
  public textSearch: string = '';

  public selectedPasswordChangePlayerId: string = '';

  public selectedDepositPlayerId: string = '';

  public selectedWithdrawPlayerId: string = '';

  public selectedBank: { bank: string; account_number: string } = {
    bank: '',
    account_number: '',
  };

  public loading: boolean = false;

  public passwordChangeDialog: boolean = false;

  public depositDialog: boolean = false;

  public withdrawDialog: boolean = false;

  public password: string = '';

  public amount: number = 0;

  public banks: Array<any> = [];

  public withdrawAmount: number = 0;

  async created() {
    await this.fetchBank();
  }

  @Watch('selectedPasswordChangePlayerId')
  public onselectedPasswordChangePlayerIdChange(value: string) {
    if (value) {
      this.passwordChangeDialog = true;
    }
  }

  @Watch('passwordChangeDialog')
  public onPasswordChangeDialog(value: boolean) {
    if (!value) {
      this.password = '';
      this.selectedPasswordChangePlayerId = '';
    }
  }

  @Watch('selectedDepositPlayerId')
  public onSelectedDepositPlayerIdChange(value: string) {
    if (value) {
      this.depositDialog = true;
    }
  }

  @Watch('depositDialog')
  public onDepositDialogChange(value: boolean) {
    if (!value) {
      this.amount = 0;
      this.selectedBank = {
        bank: '',
        account_number: '',
      };
      this.selectedDepositPlayerId = '';
    }
  }

  @Watch('selectedWithdrawPlayerId')
  public onSelectedWithdrawPlayerIdChange(value: string) {
    if (value) {
      this.withdrawDialog = true;
    }
  }

  @Watch('withdrawDialog')
  public onWithdrawDialogChange(value: boolean) {
    if (!value) {
      this.withdrawAmount = 0;
      this.selectedWithdrawPlayerId = '';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get usersUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/users`;
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  public async fetchBank() {
    const { data } = await bankService.getAllBank();
    this.banks = [...data];
  }

  async updatePlayerStatus(id: string, status: string) {
    try {
      this.loading = true;
      await playerService.updatePlayer(id, { status });
      this.loading = false;
      (this.$refs.USER_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async depositByAdmin() {
    try {
      if (!this.selectedBank.account_number) {
        this.$store.commit('Snackbar/showNotification', { message: 'กรุณาเลือกธนาคารที่ลูกค้าฝาก', error: true });
        return;
      }
      if (this.amount < 1) {
        this.$store.commit('Snackbar/showNotification', { message: 'จำนวนที่ลูกค้าฝากเงินต้องมากกว่า 1', error: true });
        return;
      }
      this.loading = true;
      await transactionService.depositByAdmin({
        to_bank: this.selectedBank.bank,
        to_account_number: this.selectedBank.account_number,
        amount: this.amount,
        user_id: this.selectedDepositPlayerId,
      });
      this.$store.commit('Snackbar/showNotification', { message: 'เติมเงินให้ลูกค้าเรียบร้อยแล้ว', error: false });
      this.loading = false;
      this.depositDialog = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async withdrawByAdmin() {
    try {
      if (this.withdrawAmount < 1) {
        this.$store.commit('Snackbar/showNotification', { message: 'จำนวนที่ต้องการถอนต้องมากกว่า 1', error: true });
        return;
      }
      this.loading = true;
      await transactionService.withdrawByAdmin({
        user_id: this.selectedWithdrawPlayerId,
        withdrawAmount: this.withdrawAmount,
      });
      this.$store.commit('Snackbar/showNotification', { message: 'ถอนเครดิตลูกค้าเรียบร้อยแล้ว', error: false });
      this.loading = false;
      this.withdrawDialog = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async updatePassword() {
    try {
      this.loading = true;
      await playerService.updatePlayer(
        this.selectedPasswordChangePlayerId,
        { password: this.password },
      );
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว', error: false });
      this.passwordChangeDialog = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}












import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Notification = namespace('Notification');

@Component
export default class NotificationConnector extends Vue {
  @Notification.State
  public withdrawRequestCreatedIds!: Array<string>;

  @Notification.State
  public depositRequestCreatedIds!: Array<string>;

  @Notification.Action
  public removeRequestState!: (type: string) => {}

  public async aware(type: string, callback?: Function) {
    await this.removeRequestState(type);
    if (callback) {
      callback();
    }
  }
}

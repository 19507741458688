/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class TransactionService extends AppService {
  public updateDepositRequest(data: { request_id: string; status: string }) {
    return this.makeRequest('PUT', '/transactions/deposit', data);
  }

  public updateWithdrawRequest(data: { request_id: string; status: string }) {
    return this.makeRequest('PUT', '/transactions/withdraw', data);
  }

  public getRequestSummary(type: string, range: string, search: string = '') {
    return this.makeRequest('GET', `/transactions/admin/${type}/summary?range=${range}&search=${search}`);
  }

  public getTransactionSummary(range: string) {
    return this.makeRequest('GET', `/transactions/summary?range=${range}`);
  }

  public depositByAdmin(data: {
    to_bank: string;
    to_account_number: string;
    amount: number;
    user_id: string;
  }) {
    return this.makeRequest('POST', '/transactions/admin-deposit', data);
  }

  public withdrawByAdmin(data: {
    withdrawAmount: number;
    user_id: string;
  }) {
    return this.makeRequest('POST', '/transactions/admin-withdraw', data);
  }
}

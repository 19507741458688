




























































import Auth from '@/connector/Auth.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Auth,
  },
})
export default class Login extends Vue {
  public username: string = '';

  public password: string = '';

  get loginData() {
    return {
      username: this.username,
      password: this.password,
    };
  }
}

<template>
  <div v-frag>
    <v-app-bar
      app
      color="white"
      clipped-left
    >
      <v-toolbar-title>
        <img
          class="mt-3"
          height="56px"
          :src="require('@/assets/logo.png')"
        />
      </v-toolbar-title>
      <auth>
        <template v-slot:loggedIn="{ logout }">
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            depressed
            color="white"
            active-class="pink white--text"
            :to="{ name: 'ChangePassword' }"
          >
            เปลี่ยนรหัสผ่าน
          </v-btn>
          <v-btn
            depressed
            color="white"
            @click="logout()"
          >
            ออกจากระบบ
          </v-btn>
        </v-toolbar-items>
        </template>
      </auth>
    </v-app-bar>
    <v-navigation-drawer
      app
      clipped
    >
      <auth>
        <template v-slot:loggedIn="{ profile }">
          <v-list-item
            v-if="profile.username === 'superadmin'"
            active-class="pink white--text"
            :to="{ name: 'Dashboard' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-chart-line
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                ข้อมูลสรุป
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <notification v-slot:default="{
            depositRequestCreatedIds,
            withdrawRequestCreatedIds,
          }">
            <v-list-item
              active-class="pink white--text"
              :to="{ name: 'DepositRequest' }"
            >
              <v-list-item-icon>
                <v-icon>
                  fas fa-money-check
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  รายการแจ้งฝาก
                  <v-chip
                    active-class="white"
                    v-if="depositRequestCreatedIds.length > 0"
                    label
                    color="purple"
                  >
                    <span class="white--text">{{ depositRequestCreatedIds.length }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="pink white--text"
              :to="{ name: 'WithdrawRequest' }"
            >
              <v-list-item-icon>
                <v-icon>
                  fas fa-hand-holding-usd
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  รายการแจ้งถอน
                  <v-chip
                    active-class="white"
                    v-if="withdrawRequestCreatedIds.length > 0"
                    label
                    color="purple"
                  >
                    <span class="white--text">{{ withdrawRequestCreatedIds.length }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </notification>
          <v-list-item
            active-class="pink white--text"
            :to="{ name: 'Transaction' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-retweet
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                รายการธุรกรรม
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="pink white--text"
            :to="{ name: 'PlayerList' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-users
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                ผู้เล่น
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="profile.username === 'superadmin'"
            active-class="pink white--text"
            :to="{ name: 'AdminList' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-user-secret
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                ผู้ช่วย
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="profile.username === 'superadmin'"
            active-class="pink white--text"
            :to="{ name: 'PromotionList' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-percentage
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                โปรโมชั่น
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="profile.username === 'superadmin'"
            active-class="pink white--text"
            :to="{ name: 'BankList' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-university
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                บัญชีธนาคาร
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="profile.username === 'superadmin'"
            active-class="pink white--text"
            :to="{ name: 'TokenManage' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-cookie
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                จัดการการใช้งานระบบ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="profile.username === 'superadmin'"
            active-class="pink white--text"
            :to="{ name: 'AffiliateSetting' }"
          >
            <v-list-item-icon>
              <v-icon>
                fas fa-users-cog
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                ระบบแนะนำ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </auth>
    </v-navigation-drawer>
    <v-main>
      <v-container style="height: 100%;">
        <slot />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Auth from '@/connector/Auth.vue';
import Notification from '@/connector/Notification.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Auth,
    Notification,
  },
})
export default class DefaultLayout extends Vue {}
</script>

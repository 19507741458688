var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('div',{staticClass:"d-flex align-center ma-0 mb-2"},[_c('h1',{staticClass:"font-weight-regular"},[_vm._v("แก้ไขผู้เล่น")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","to":{ name: 'PlayerList' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-arrow-left ")]),_vm._v(" ย้อนกลับ ")],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey lighten-5","flat":""}},[_c('div',{staticClass:"pa-3"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[_c('center',[_vm._v(" ชื่อผู้ใช้ ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[_c('center',[_vm._v(" รหัสผ่าน ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-text-field',{attrs:{"type":"password","filled":"","rounded":"","dense":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[_c('center',[_vm._v(" ธนาคาร ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-select',{attrs:{"filled":"","rounded":"","dense":"","items":[
                      { text: 'ธนาคารกสิการไทย', value: 'kbank' },
                      { text: 'ธนาคารไทยพานิชย์', value: 'scb' },
                      { text: 'ธนาคารกรุงไทย', value: 'ktb' },
                      { text: 'ธนาคารกรุงเทพ', value: 'bbl' },
                      { text: 'ธนาคารธนชาต', value: 'tbank' },
                      { text: 'ธนาคารทหารไทย', value: 'tmb' },
                      { text: 'ธนยาคาร UOB', value: 'uob' },
                      { text: 'ธนาคารกรุงศรีอยุธยา', value : 'bay'},
                      { text: 'ธนาคารออมสิน', value : 'gsb'},
                      { text: 'ธนาคาร ธกส', value: 'bacc' } ]},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[_c('center',[_vm._v(" เลขที่บัญชี ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":""},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[_c('center',[_vm._v(" ชื่อบัญชี ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":""},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"1"}},[_c('center',[_vm._v(" Line Id ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":""},model:{value:(_vm.lineId),callback:function ($$v) {_vm.lineId=$$v},expression:"lineId"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-btn',{attrs:{"color":"pink","depressed":"","block":"","dark":"","loading":_vm.loading},on:{"click":_vm.updatePlayer}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" fas fa-save ")]),_vm._v(" บันทึก ")],1)],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
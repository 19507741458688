





































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PlayerService from '@/services/PlayerService';

const playerService = new PlayerService();
/* eslint-disable @typescript-eslint/camelcase */
@Component
export default class EditPlayer extends Vue {
  public accountName: string = '';

  public bank: string = '';

  public accountNumber: string = '';

  public lineId: string = '';

  public password: string = '';

  public username: string = '';

  public loading: boolean = false;

  async created() {
    const { data } = await playerService.getPlayerById(this.$route.params.id);
    this.accountName = data.account_name;
    this.accountNumber = data.account_number;
    this.bank = data.bank;
    this.username = data.username;
    this.lineId = data.line_id;
  }

  async updatePlayer() {
    try {
      this.loading = true;
      await playerService.updatePlayer(this.$route.params.id, {
        account_name: this.accountName ? this.accountName : undefined,
        account_number: this.accountNumber ? this.accountNumber : undefined,
        bank: this.bank ? this.bank : undefined,
        username: this.username ? this.username : undefined,
        password: this.password ? this.password : undefined,
        line_id: this.lineId ? this.lineId : undefined,
      });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}

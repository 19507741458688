import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/plugins/vue-frag';
import '@/plugins/debounce';
import DefaultLayout from '@/layout/DefaultLayout.vue';
import store from '@/store';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.component('default-layout', DefaultLayout);

Vue.filter('statusToTH', (value: string) => {
  if (value === 'PENDING') return 'อยู่ระหว่างดำเนินงาน';
  if (value === 'APPROVED') return 'สำเร็จ';
  if (value === 'REJECTED') return 'ปฏิเสธ';
  return '';
});

store.dispatch('Notification/initiateNotificationState');
store.dispatch('Notification/subscribeNotification');
store.dispatch('Auth/autoLogin').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});

/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class PlayerService extends AppService {
  public updatePlayer(
    id: string,
    updateData: {
      username?: string;
      password?: string;
      line_id?: string;
      bank?: string;
      status?: string;
      account_name?: string;
      account_number?: string;
    },
  ) {
    return this.makeRequest('PUT', `/users/${id}`, updateData);
  }

  public getPlayerById(id: string) {
    return this.makeRequest('GET', `/users/${id}`);
  }
}

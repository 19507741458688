


























































































































































































































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import BaseLineChart from '@/components/BaseLineChart.vue';
import BasePieChart from '@/components/BasePieChart.vue';
import TransactionService from '@/services/TransactionService';
import UserService from '@/services/UserService';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';

const transactionService = new TransactionService();
const userService = new UserService();

@Component({
  components: {
    BaseLineChart,
    BasePieChart,
    BaseDateRangeSearch,
  },
})
export default class Dashboard extends Vue {
  public dateMenu: boolean = false;

  public range: string = [this.today, this.today].toString();

  public approvedDepositData: Array<any> = [];

  public approvedWithdrawData: Array<any> = [];

  public registerRawData: Array<object> = [];

  public rangeDepositAmountSummary: number = 0;

  public rangeWithdrawAmountSummary: number = 0;

  public todayDepositTransaction: number = 0;

  public todayWithdrawTransaction: number = 0;

  public todayRegister: number = 0;

  // eslint-disable-next-line class-methods-use-this
  get today(): string {
    return moment().format('YYYY-MM-DD');
  }

  get formatedRange(): string {
    return this.range.replace(',', ' ถึง ');
  }

  // eslint-disable-next-line class-methods-use-this
  get currentDate(): Array<string> {
    const { today } = this;
    return [today, today];
  }

  get isSameDateSelected(): boolean {
    const range = this.range.split(',');
    return range[0] === range[1];
  }

  // eslint-disable-next-line class-methods-use-this
  get currentRange(): Array<string> {
    const range = this.range.split(',');
    const start = moment(range[0]);
    const end = moment(range[1]);
    const duration = moment.duration(end.diff(start));
    const diff = duration.asDays();
    const days = [];
    for (let i = 0; i <= diff; i += 1) {
      days.push(moment(range[0]).add(i, 'days').format('YYYY-MM-DD'));
    }
    return days;
  }

  search(value: string) {
    this.range = value;
  }

  transactionStaticData() {
    console.log('this.currentRange', this.currentRange);
    const depositData = new Array(this.currentRange.length).fill(0);
    const withdrawData = new Array(this.currentRange.length).fill(0);
    this.approvedDepositData.forEach((data): any => {
      const index = moment(data.created_at).format('D');
      depositData[Number(index) - 1] += data.amount;
    });
    this.approvedWithdrawData.forEach((data): any => {
      const index = moment(data.created_at).format('D');
      withdrawData[Number(index) - 1] += data.amount;
    });
    const data = {
      labels: this.currentRange,
      datasets: [{
        data: [...depositData],
        borderColor: '#E91E63',
        fill: true,
        label: 'ยอดฝาก',
      }, {
        data: [...withdrawData],
        borderColor: '#9C27B0',
        fill: true,
        label: 'ยอดถอน',
      }],
    };
    return data;
  }

  registerStaticData() {
    const registerStaticData = new Array(31).fill(0);
    this.registerRawData.forEach((data: any) => {
      const index = moment(data.created_at).day();
      registerStaticData[Number(index)] += 1;
    });
    const data = {
      labels: this.currentRange,
      datasets: [{
        data: registerStaticData,
        borderColor: '#E91E63',
        fill: true,
        label: 'จำนวนสมาชิก',
      }],
    };
    return data;
  }

  reachableStatic() {
    const registerStaticData = new Array(6).fill(0);
    this.registerRawData.forEach((data: any) => {
      if (data.known_us_from === 'sms') registerStaticData[0] += 1;
      if (data.known_us_from === 'search_engine') registerStaticData[1] += 1;
      if (data.known_us_from === 'youtube') registerStaticData[2] += 1;
      if (data.known_us_from === 'facebook') registerStaticData[3] += 1;
      if (data.known_us_from === 'friend') registerStaticData[4] += 1;
      if (data.known_us_from === 'agent') registerStaticData[5] += 1;
    });
    const data = {
      datasets: [{
        data: registerStaticData,
        backgroundColor: ['#075E54', '#720E9E ', '#FF0000', '#4267B2', '#ff9900', '#FF5722'],
      }],
      labels: [
        'sms',
        'search_engine',
        'youtube',
        'facebook',
        'friend',
        'agent',
      ],
    };
    return data;
  }

  async created() {
    const { currentRange } = this;
    const { data: depositRawData } = await transactionService.getRequestSummary('deposit', `${currentRange[0]},${currentRange[currentRange.length - 1]}`);
    const { data: withdrawRawData } = await transactionService.getRequestSummary('withdraw', `${currentRange[0]},${currentRange[currentRange.length - 1]}`);
    const { data: registeredUser } = await userService.userRegisterSummary();
    this.registerRawData = [...registeredUser];
    this.todayRegister = registeredUser.filter((userData: any) => moment().format('YYYY-MM-DD') === moment(userData.created_at).format('YYYY-MM-DD')).length;
    // DEPOSIT SECTION
    const todayDepositTransaction = depositRawData.filter((depositData: any) => moment().format('YYYY-MM-DD') === moment(depositData.created_at).format('YYYY-MM-DD'));
    this.approvedDepositData = todayDepositTransaction.filter((depositData: any) => depositData.status === 'APPROVED');
    this.todayDepositTransaction = todayDepositTransaction.length;
    this.rangeDepositAmountSummary = todayDepositTransaction
      .filter((depositData: any) => depositData.status === 'APPROVED')
      .reduce((acc: number, current: any) => acc + current.amount, 0);
    // WITHDRAW SECTION
    const todayWithdrawTransaction = withdrawRawData.filter((withdrawData: any) => moment().format('YYYY-MM-DD') === moment(withdrawData.created_at).format('YYYY-MM-DD'));
    this.approvedWithdrawData = todayWithdrawTransaction.filter((withdrawData: any) => withdrawData.status === 'APPROVED');
    this.todayWithdrawTransaction = todayWithdrawTransaction.length;
    this.rangeWithdrawAmountSummary = todayWithdrawTransaction
      .filter((withdrawData: any) => withdrawData.status === 'APPROVED')
      .reduce((acc: number, current: any) => acc + current.amount, 0);
  }
}
